import { CoreModule } from '@core/core.module';
import { NgModule } from '@angular/core';
import { BaseComponent } from './base/base.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HeaderTitleComponent } from './header/header-title/header-title.component';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { TopbarComponent } from './header/topbar/topbar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SubheaderComponent } from './subheader/subheader.component';
import { MenuHorizontalComponent } from './menu-horizontal/menu-horizontal.component';
import { FooterComponent } from './footer/footer.component';
import { PartialsModule } from '@partials/partials.module';
import { HeaderComponent } from './header/header.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { TranslateModule } from '@ngx-translate/core';

// declare var baseUrl = window.location.href;

@NgModule({
	declarations: [BaseComponent, HeaderComponent, HeaderTitleComponent, TopbarComponent, SubheaderComponent, MenuHorizontalComponent, FooterComponent, ErrorPageComponent],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		NgbProgressbarModule,
		MatButtonModule,
		MatIconModule,
		TranslateModule,
		CoreModule,
		/* Metronic */
		PartialsModule,
	],
	exports: [BaseComponent, ErrorPageComponent],
})
export class ThemeModule {}
