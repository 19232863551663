// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/security/guards/auth.guard';
import { FormsGuard } from '@core/security/guards/forms.guard';
import { BaseComponent } from '@theme/base/base.component';
import { ErrorPageComponent } from '@theme/error-page/error-page.component';
import { environment } from 'environments/environment';
import { EnumForm, KEY_FORM } from '~shared/enums/EnumForm';

// Components

const routes: Routes = [
	{
		path: environment.PATH_BASE,
		component: BaseComponent,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		children: [
			{
				path: 'dashboard',
				data: { titulo: 'Dashboard' },
				loadChildren: () => import('app/modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
			},
			{
				path: 'control',
				loadChildren: () => import('app/modules/control/control.module').then((m) => m.ControlModule),
			},
			{
				path: 'mensaje',
				loadChildren: () => import('app/modules/message/message.module').then((m) => m.MessageModule),
				data: {
					title: 'Mensaje',
					[KEY_FORM]: EnumForm.Document,
				},
				canActivate: [FormsGuard],
			},
			{
				path: 'herramientas',
				loadChildren: () => import('app/modules/herramienta/herramienta.module').then((m) => m.HerramientaModule),
				data: {
					title: 'Herramienta',
					[KEY_FORM]: EnumForm.Herramienta,
					canActivate: [FormsGuard],
				},
			},
			{
				path: '',
				redirectTo: `/${environment.PATH_BASE}/control/documentos`,
				pathMatch: 'full',
			},
			{
				path: '**',
				redirectTo: '../error/403',
				pathMatch: 'full',
			},
		],
	},
	{
		path: 'error/403',
		component: ErrorPageComponent,
		data: {
			type: 'error-v6',
			code: 403,
			title: 'Prohibido pasar',
			desc: 'Tranquilo ratón, aún no desarrollamos esto.<br> Please, contact Almircar.',
		},
	},
	{
		path: 'error/acceso-denegado',
		component: ErrorPageComponent,
		data: {
			type: 'error-v6',
			code: 403,
			title: 'Prohibido pasar',
			desc: 'No tiene permisos para ver esta página',
		},
	},
	{ path: '**', redirectTo: environment.PATH_BASE, pathMatch: 'full' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: 'disabled', //migracion
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
