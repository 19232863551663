import { MenuConfigService } from '@core/_base/layout/services/menu-config.service';
import { MenuHorizontalService } from '@core/_base/layout/services/menu-horizontal.service';
import { Router } from '@angular/router';
// Angular
import { AfterViewInit, Component, Input, OnInit, Output, DoCheck } from '@angular/core';
import { MenuConfig } from '@core/_config/menu.config';

@Component({
	selector: 'kt-quick-action',
	templateUrl: './quick-action.component.html',
})
export class QuickActionComponent implements OnInit, AfterViewInit {
	//output test
	// @Output() redirect:EventEmitter<any> = new EventEmitter();
	bartype: any = { transporte: 'T', ventas: 'V', carga: 'C' };
	menuconfig: MenuConfig = new MenuConfig();

	// Public properties

	// Set icon class name
	@Input() icon = 'flaticon2-gear';

	@Input() iconType: '' | 'warning' = '';

	// Set true to icon as SVG or false as icon class
	@Input() useSVG!: boolean;

	// Set bg image path
	@Input() bgImage!: string;

	// Set skin color, default to light
	@Input() skin: 'light' | 'dark' = 'light';

	@Input() gridNavSkin: 'light' | 'dark' = 'light';
	constructor(private router: Router, private menuHorConfigService: MenuHorizontalService, private menuConfigService: MenuConfigService) {}
	ngAfterViewInit(): void {}
	ngOnInit(): void {}

	onSVGInserted(svg: any) {
		svg.classList.add('kt-svg-icon', 'kt-svg-icon--success', 'kt-svg-icon--lg');
	}

	sistemas(order: any) {
		switch (order) {
			case 1:
				// this.redirect.emit(this.bartype.ventas)
				this.menuConfigService.loadConfigs(this.menuconfig.defaults);
				// this.menuHorConfigService.loadMenu();
				// this.router.navigateByUrl("/ventas");
				window.open('/ventas', '_blank');
				break;
			case 2:
				// this.redirect.emit(this.bartype.carga)
				this.menuConfigService.loadConfigs(this.menuconfig.defaults);
				// this.menuHorConfigService.loadMenu();
				// this.router.navigateByUrl("/carga");
				window.open('/carga', '_blank');
				break;
			case 3:
				// this.router.navigateByUrl("/registrar");
				// window.open("/transporte", "_blank");
				break;
			default:
				// this.router.navigateByUrl("/home");
				window.open('/home', '_blank');
		}
	}
}
